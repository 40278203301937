/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as pathToRegexp from 'path-to-regexp';

export const authenticatedPaths = {
  ACCOUNT: '/account',
  ACCOUNTS: '/accounts',
  ACCOUNT_DETAILS: '/accounts/:accountId',

  CHECKOUT_FAILURE: '/checkout-failure',
  CHECKOUT_ORDER: '/checkout/:id',
  CHECKOUT_SUCCESS: '/checkout/:id/success',

  ORDER_PAYMENT_RESULT: '/checkout/:id/payment-result',
  ORDER_PAYMENT_FAILURE: '/checkout/:id/payment-failure',
  ORDER_PAYMENT_PENDING: '/checkout/:id/payment-pending',

  LOOKBOOK: '/lookbook',
  LOOKBOOK_SINGLE: '/lookbook/:lookbookId',
  LOOKBOOK_SINGLE_PRODUCT: '/lookbook/:lookbookId/product/:productId',

  ORDERS: '/orders',
  ORDERSHEETS: '/ordersheets',
  ORDER_DETAILS: '/orders/:id',

  PRODUCTS_ORDER: '/products/:id?',

  PAGES: '/pages/:pageSlug/:id?',
  PAGES_LIST: '/pages',

  CMS_EDITOR: '/cms/:pageId',
  CMS_PREVIEW: '/cms/:pageId/preview',

  HOME: '/home/:id?',
  ROOT: '/',

  SELECTIONS: '/selections',

  SETTINGS: '/settings',
  TRANSLATIONS: '/translations',

  LINESHEETS: '/linesheets',
  TOOLS: '/tools',

  INVOICES: '/invoices',
  INVOICE_DETAILS: '/invoice/:invoiceId/:invoiceHash',
  INVOICE_PENDING: '/invoice/:invoiceId/:invoiceHash/pending',

  PROCESSING_PAYMENT: '/invoice/:invoiceId/:invoiceHash/processing',
};

export const unAuthenticatedPaths = {
  LOGIN: '/login',
  PAGE_NOT_FOUND: '/404',
  PASSWORD_CHANGE: '/password/change',
  PASSWORD_FORGOT: '/password/forgot',
  LINK_EXPIRED: '/link-expired',
  WELCOME: '/welcome',
};

const SHOWROOM_SUPPORT_URL = 'https://support.centra.com/showroom';

export const externalLinks = {
  SUPPORT: SHOWROOM_SUPPORT_URL,
  SUPPORT_TRANSLATIONS: `${SHOWROOM_SUPPORT_URL}/translations-in-showroom`,
};

export const paths: typeof authenticatedPaths & typeof unAuthenticatedPaths = {
  ...authenticatedPaths,
  ...unAuthenticatedPaths,
};

export const VALID_ORDER_PATHS = [
  paths.CHECKOUT_FAILURE,
  paths.CHECKOUT_ORDER,
  paths.HOME,
  paths.ORDER_DETAILS,
  paths.PAGES,
  paths.PRODUCTS_ORDER,
];
/**
 * compiled paths are functions which accept object with parameters which correspond to path parameters
 * e.g. compiledPaths.ACCOUNT_DETAILS({ accountId: 5}) → /accounts/5
 */
type CompiledPaths = Record<keyof typeof paths, (pathParams: Record<string, Unrestricted>) => string>;

export const compiledPaths = (Object.keys(paths) as (keyof typeof paths)[]).reduce<CompiledPaths>(
  (acc, key: keyof typeof paths) => ({
    ...acc,
    [key]: pathToRegexp.compile(paths[key]),
  }),
  Object.create({}),
);
